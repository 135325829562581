import React, { useState } from "react"

// Components
import VerticalTitle from "components/vertical-title/"
import Dropdown from "components/dropdown/"

// Icons
import IconSuccess from "assets/icons/icon-success.inline.svg"

// Data
import { countries } from "data/"

const Newsletter = () => {
  const [formFields, setFormFields] = useState({
    firstname: "",
    lastname: "",
    email: "",
    country_region: "",
    phone: "",
    intended_relationship: "",
  })
  const [formValidation, setFormValidation] = useState({
    firstname: true,
    lastname: true,
    email: true,
    country_region: true,
    phone: true,
    intended_relationship: true,
  })
  const [showCover, setShowCover] = useState(true)
  const [activeStep, setActiveStep] = useState(1)

  const handleInputChange = event => {
    const {
      target: { name, value },
    } = event

    formFields[name] = value

    setFormFields({ ...formFields, [name]: value })

    if (Object.values(formValidation).includes(false)) {
      const _formValidation = formValidation

      Object.keys(_formValidation).forEach(key => {
        _formValidation[key] = true
      })

      setFormValidation({ ...formValidation, _formValidation })
    }
  }

  const handleCountryChange = value => {
    setFormFields({ ...formFields, country_region: value })
  }

  const handleIntendedRelationship = value => {
    setFormFields({ ...formFields, intended_relationship: value })
  }

  const formIsValid = () => !Object.values(formValidation).includes(false)

  const goToStepTwo = () => {
    const _formValidation = formValidation
    const stepOneFields = ["firstname", "lastname", "email"]

    Object.entries(formFields).forEach(([key, value]) => {
      if (stepOneFields.includes(key)) {
        _formValidation[key] = value !== ""
      }
    })

    setFormValidation({ ...formValidation, _formValidation })

    if (formIsValid()) {
      setActiveStep(2)
    }
  }

  const handleValidation = () => {
    const _formValidation = formValidation

    Object.entries(formFields).forEach(([key, value]) => {
      _formValidation[key] = value !== ""
    })

    setFormValidation({ ...formValidation, _formValidation })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    handleValidation()

    if (formIsValid()) {
      const portalId = "7628932"
      const formId = "269629d4-e963-487f-8500-12f70bf1e9b8"
      const data = {
        // we'll pass this array to HubSpot's API
        fields: [],
        context: {
          pageUri: "https://ndb.money",
          pageName: "NDB Money",
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: "I agree to allow VOLTAMOND SA to store and process my personal data.",
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: "I agree to receive marketing communications from VOLTAMOND SA.",
              },
            ],
          },
        },
      }

      Object.entries(formFields).forEach(([key, value]) => {
        data.fields.push({
          name: key,
          value,
        })
      })

      fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )

      setActiveStep(3)
    }
  }

  return (
    <section id="newsletter" className="home__newsletter">
      <VerticalTitle
        title="Subscribe to our newsletter"
        className="title mb-4 mb-md-0"
        animatedMobile
        animated
      />
      <div className="container">
        <div className="newsletter-wrapper">
          <div
            className={`cover d-flex align-items-center justify-content-center ${
              showCover && "cover--active"
            }`}
          >
            <div className="text-center">
              <h2 className="highlight mb-4">Subscribe to our newsletter</h2>
              <p>
                Don’t miss any news. <br />
                You can unsubscribe in any time.
              </p>
              <button
                type="button"
                className="mt-5 button button--green"
                onClick={() => setShowCover(false)}
              >
                Subscribe
              </button>
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="newsletter__form form"
            data-active-step={activeStep}
          >
            <div className="form__step form__step--1 text-center" data-step="1">
              <div>
                <h2 className="highlight mb-4">Step 1 of 2</h2>
                <div className="path mb-4 mb-sm-5">
                  <span className="active" />
                  <span />
                  <span />
                </div>

                <div className="form__input mb-4">
                  <input
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    onChange={handleInputChange}
                  />
                  {!formValidation.firstname && (
                    <p className="form__message color--red">
                      Please enter your first name
                    </p>
                  )}
                </div>

                <div className="form__input mb-4">
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    onChange={handleInputChange}
                  />
                  {!formValidation.lastname && (
                    <p className="form__message color--red">
                      Please enter your last name
                    </p>
                  )}
                </div>

                <div className="form__input mb-4">
                  <input
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    onChange={handleInputChange}
                  />
                  {!formValidation.email && (
                    <p className="form__message color--red">
                      Please enter your email
                    </p>
                  )}
                </div>
              </div>

              <div className="step__controls text-center">
                <button
                  type="button"
                  className="button"
                  onClick={() => goToStepTwo()}
                >
                  Next
                </button>
              </div>
            </div>

            <div className="form__step form__step--2 text-center" data-step="2">
              <div>
                <h2 className="highlight mb-4">Step 2 of 2</h2>
                <div className="path mb-4 mb-sm-5">
                  <span className="active" />
                  <span className="active" />
                  <span />
                </div>

                <div className="row form__input mb-4">
                  <div className="col col-5">
                    <Dropdown
                      title="Country"
                      options={countries}
                      callbackFunction={handleCountryChange}
                    />
                    {!formValidation.firstname && (
                      <p className="form__message color--red">
                        Please enter your country region
                      </p>
                    )}
                  </div>
                  <div className="col col-7">
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      onChange={handleInputChange}
                    />
                    {!formValidation.phone && (
                      <p className="form__message color--red">
                        Please enter your phone number
                      </p>
                    )}
                  </div>
                </div>

                <div className="form__input mb-4">
                  <Dropdown
                    title="Intended relationship"
                    options={[
                      "Investor",
                      "Journalist/Media Professional",
                      "Customer",
                      "Partner",
                      "Jobseeker",
                      "Other/I'm just interested in NDB",
                    ]}
                    callbackFunction={handleIntendedRelationship}
                  />
                  {!formValidation.intended_relationship && (
                    <p className="form__message color--red">
                      Please enter your intended relationship
                    </p>
                  )}
                </div>
              </div>

              <div className="step__controls row">
                <div className="col-6">
                  <button
                    type="button"
                    className="button mr-3"
                    onClick={() => setActiveStep(1)}
                  >
                    Previous
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="submit"
                    className="button button--green"
                    onClick={handleSubmit}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>

            <div
              className="form__step d-flex align-items-center justify-content-center text-center"
              data-step="3"
            >
              <div>
                <IconSuccess className="mb-3" />
                <h2 className="mb-3">Success!</h2>
                <p>You are now subscribed to our newsletter.</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
