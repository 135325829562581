import React from "react"

// Components
import { ButtonLink } from "components/button/"

// Icons
import IconCity from "assets/icons/vision/icon-city.svg"

const ExploreTheCity = () => (
  <section className="home__explore-the-city">
    <div className="container">
      <h1 className="title highlight mb-4">NDB City</h1>
      <h3 className="mb-2">Innovation ecosystem</h3>
      <p className="mb-5">
        An advance tech hub to deliver interdisciplinary innovative solutions.
      </p>

      <ButtonLink
        to="https://ndb.city"
        state={{
          hideCover: true,
        }}
      >
        Explore the city
      </ButtonLink>
    </div>

    <div className="city" style={{ backgroundImage: `url(${IconCity})` }}>
      <div className="pin ndb-hub">
        <span>NBD HUB</span>
      </div>

      <div className="pin voltaenergy">
        <span>VOLTAENERGY</span>
      </div>

      <div className="pin voltasys">
        <span>VOLTASYS</span>
      </div>

      <div className="pin voltasem">
        <span>VOLTASEM</span>
      </div>

      <div className="pin voltaspace">
        <span>VOLTASPACE</span>
      </div>

      <div className="pin voltamega">
        <span>VOLTAMEGA</span>
      </div>

      <div className="pin voltamond">
        <span>VOLTAMOND</span>
      </div>

      <div className="pin voltademy">
        <span>VOLTADEMY</span>
      </div>

      <div className="pin voltayou">
        <span>VOLTAYOU</span>
      </div>

      <div className="pin bloom">
        <span>BLOOM</span>
      </div>

      <div className="pin ndb-foundation">
        <span>
          NDB
          <br />
          FOUNDATION
        </span>
      </div>
    </div>
    <div className="gradient" />
  </section>
)

export default ExploreTheCity
