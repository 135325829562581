import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"

// Sections
import Hero from "sections/home/hero"
import Features from "sections/home/features"
import About from "sections/home/about"
import Partners from "sections/home/partners"
import DownloadApp from "sections/home/download-app"
import Community from "sections/home/community"
import ExploreTheCity from "sections/home/explore-the-city"
import Newsletter from "sections/home/newsletter"
import ContactUs from "sections/home/contact-us"

const Home = () => (
  <Layout>
    <SEO title="Home | NDB" />
    <Hero />
    <Features />
    <About />
    <Partners />
    <DownloadApp />
    <Community />
    <ExploreTheCity />
    <Newsletter />
    <ContactUs />
  </Layout>
)

export default Home
