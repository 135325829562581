import React, { useRef, useState } from "react"

// Libraries
import Carousel from "react-slick"

// Styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Components
import VerticalTitle from "components/vertical-title/"

// Icons
import IconAppStore from "assets/icons/icon-app-store.inline.svg"
import IconPlayStore from "assets/icons/icon-play-store.inline.svg"

// Images
import IconDownload1 from "assets/icons/home/download-image-1.png"
import IconDownload2 from "assets/icons/home/download-image-2.png"
import IconDownload3 from "assets/icons/home/download-image-3.png"
import IconDownload4 from "assets/icons/home/download-image-4.png"
import IconDownload5 from "assets/icons/home/download-image-5.png"
import IconDownload6 from "assets/icons/home/download-image-6.png"

const DownloadApp = () => {
  const [activeSlide, setActiveSlide] = useState(0)

  const carousel = useRef()

  const data = [
    {
      title: "NDB Wallet",
      description:
        "Check your balance quickly and safely, send, receive or trade your tokens.",
      image: IconDownload1,
    },
    {
      title: "NDB Invest",
      description:
        "Stake your tokens in a project, partner, or licensee who NDB powers to earn dividends.",
      image: IconDownload2,
    },
    {
      title: "NDB Smart Energy",
      description:
        "Smart subscriptions to monitor, manage and optimize energy consumption alongside product utilization.",
      image: IconDownload3,
    },
    {
      title: "Product Intelligence",
      description:
        "An easy interface solution to control, track, diagnose, transfer the ownership, license, and manage the subscription of NDB products.",
      image: IconDownload4,
    },
    {
      title: "NDB Rewards",
      description:
        "Receive VOLT TOKENs as a reward in many ways. Purchase products, support the projects, sell energy to the grid, mine, and many more.",
      image: IconDownload5,
    },
    {
      title: "NDB Cyber Secure",
      description:
        "Secure Sockets Layer technology to offer safety to protect your wallet, tokens, products, and privacy.",
      image: IconDownload6,
    },
  ]

  const handleBulletClick = index => {
    if (carousel.current) {
      carousel.current.slickGoTo(index)
    }
  }

  const handleChange = index => {
    setActiveSlide(index)
  }

  const carouselOptions = {
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,
    fade: true,
    beforeChange: (current, next) => handleChange(next),
  }

  return (
    <section className="home__download-app section">
      <div className="container">
        <VerticalTitle title="Application" />

        <div className="mx-lg-auto">
          <div className="download-app__carousel">
            <Carousel
              ref={slider => {
                carousel.current = slider
              }}
              className="w-100"
              {...carouselOptions}
            >
              {data &&
                data.map(slide => (
                  <div className="slide w-100 d-flex align-items-flex-end">
                    <div className="row">
                      <div className="col-4 col-sm-6 col-md-5">
                        <img src={slide.image} className="image" alt="" />
                      </div>

                      <div className="col-8 col-sm-6 col-md-7">
                        <div className="content text-center">
                          <div className="w-100">
                            <p className="title mb-3 font-weight--700">
                              {slide.title}
                            </p>
                            <p>{slide.description}</p>
                          </div>
                          <div className="buttons">
                            <a
                              href="/"
                              className="button d-flex align-items-center justify-content-center"
                            >
                              <IconAppStore />
                              <span className="d-none d-md-block">
                                App Store
                              </span>
                              <span className="coming-soon d-none d-md-block">
                                Coming soon
                              </span>
                            </a>

                            <a
                              href="/"
                              className="button d-flex align-items-center justify-content-center"
                            >
                              <IconPlayStore />
                              <span className="d-none d-md-block">
                                Play Store
                              </span>

                              <span className="coming-soon d-none d-md-block">
                                Coming soon
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>

          <div className="w-100 mt-5 d-flex align-items-center">
            <h2 className="font-weight--700">0{activeSlide + 1}</h2>
            <div className="progress-bar mx-4 d-flex justify-content-between">
              {data.map((slide, index) => (
                <button
                  key={slide.title}
                  type="button"
                  className={
                    activeSlide === index ? "bullet bullet--active" : "bullet"
                  }
                  style={{
                    width: `${100 / data.length}%`,
                  }}
                  onClick={() => handleBulletClick(index)}
                  aria-label={`Go to ${index}`}
                />
              ))}
            </div>
            <h2 className="font-weight--700">0{data.length}</h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DownloadApp
