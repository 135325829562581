import React, { useState } from "react"

// Components
import Dropdown from "components/dropdown"

// Icons
import IconChat from "assets/icons/icon-chat.inline.svg"
import { countries } from "data"

const ContactUs = () => {
  const [formFields, setFormFields] = useState({
    firstname: "",
    lastname: "",
    email: "",
    country_region: "",
    phone: "",
    intended_relationship: "",
    message: "",
  })
  const [formValidation, setFormValidation] = useState({
    firstname: true,
    lastname: true,
    email: true,
    country_region: true,
    phone: true,
    intended_relationship: true,
    message: true,
  })
  const [showSuccess, setShowSuccess] = useState(false)

  const handleIntendedRelationship = value => {
    setFormFields({ ...formFields, intended_relationship: value })
  }

  const handleCountryChange = value => {
    setFormFields({ ...formFields, country_region: value })
  }

  const handleInputChange = event => {
    const {
      target: { name, value },
    } = event

    setFormFields({ ...formFields, [name]: value })

    if (Object.values(formValidation).includes(false)) {
      const _formValidation = formValidation

      Object.keys(_formValidation).forEach(key => {
        _formValidation[key] = true
      })

      setFormValidation({ ...formValidation, _formValidation })
    }
  }

  const handleFormValidation = () => {
    const _formValidation = formValidation

    Object.entries(formFields).forEach(([key, value]) => {
      _formValidation[key] = value !== ""
    })

    setFormValidation({ ...formValidation, _formValidation })
  }

  const formIsValid = () => !Object.values(formValidation).includes(false)

  const handleSubmit = event => {
    event.preventDefault()

    handleFormValidation()

    if (formIsValid()) {
      const portalId = "7628932"
      const formId = "14d4b2fa-6cba-49a8-9488-891110f280a8"
      const data = {
        // we'll pass this array to HubSpot's API
        fields: [],
        context: {
          pageUri: "https://ndb.money/#contact",
          pageName: "NDB Money",
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: "I agree to allow VOLTAMOND SA to store and process my personal data.",
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: "I agree to receive marketing communications from VOLTAMOND SA.",
              },
            ],
          },
        },
      }

      Object.entries(formFields).forEach(([key, value]) => {
        data.fields.push({
          name: key,
          value,
        })
      })

      fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )

      setShowSuccess(true)
    }
  }

  return (
    <section id="contactUs" className="home__contact-us">
      <button
        type="button"
        className="contact-us__chat-button d-none d-md-block"
      >
        <IconChat />
      </button>

      <div className="container">
        <div className="w-100">
          <div className="vertical-title d-md-none mb-3 mb-sm-4">
            <h1 className="highlight">Contact Us</h1>
          </div>

          <div className="desktop-title d-none d-md-block mb-5 text-center">
            <h2 className="highlight">Contact Us</h2>
          </div>

          <form className="contact-us__form form" onSubmit={handleSubmit}>
            <div className="form__input mb-2 mb-sm-4">
              <div className="row">
                <div className="col-12 col-sm-6 mb-2 mb-sm-0">
                  <input
                    type="text"
                    name="firstname"
                    placeholder="First name"
                    onChange={handleInputChange}
                  />
                  {!formValidation.firstname && (
                    <p className="form__message color--red">
                      Please enter your first name
                    </p>
                  )}
                </div>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Last name"
                    onChange={handleInputChange}
                  />
                  {!formValidation.lastname && (
                    <p className="form__message color--red">
                      Please enter your last name
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="form__input mb-2 mb-sm-4">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <input
                    type="email"
                    name="email"
                    className="mb-2 mb-sm-0"
                    placeholder="Email"
                    onChange={handleInputChange}
                  />
                  {!formValidation.phone && (
                    <p className="form__message color--red">
                      Please enter your email address
                    </p>
                  )}
                </div>
                <div className="col-12 col-sm-6 mb-2 mb-sm-0">
                  <Dropdown
                    title="Country"
                    options={countries}
                    callbackFunction={handleCountryChange}
                  />
                  {!formValidation.country_region && (
                    <p className="form__message color--red">
                      Please enter your country
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="form__input mb-2 mb-sm-4">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="bg-white">
                    <span
                      className={`text-dark fs-17 ${
                        formFields.country_region === "" ? "pl-0" : "pl-1"
                      }`}
                    >
                      {formFields.country_region}
                    </span>
                    <input
                      type="text"
                      name="phone"
                      className="mb-2 mb-sm-0 w-auto border-0 pl-1 align-middle"
                      placeholder="Phone number"
                      onChange={handleInputChange}
                    />
                  </div>

                  {!formValidation.phone && (
                    <p className="form__message color--red">
                      Please enter your phone number
                    </p>
                  )}
                </div>
                <div className="col-12 col-sm-6">
                  <Dropdown
                    title="Intended relationship"
                    options={[
                      "Investor",
                      "Journalist/Media Professional",
                      "Customer",
                      "Partner",
                      "Jobseeker",
                      "Other/I'm just interested in NDB",
                    ]}
                    callbackFunction={handleIntendedRelationship}
                  />
                  {!formValidation.intended_relationship && (
                    <p className="form__message color--red">
                      Please enter your intended relationship
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="form__input mb-2 mb-sm-4">
              <div className="row">
                <div className="col-12">
                  <textarea
                    rows="3"
                    name="message"
                    placeholder="Message"
                    onChange={handleInputChange}
                  />
                  {!formValidation.intended_relationship && (
                    <p className="form__message color--red">
                      Please enter your message
                    </p>
                  )}
                </div>
              </div>
            </div>

            <p className="disclaimer mt-4 mb-3">
              Voltamond SA is committed to protecting and respecting your
              privacy, and we’ll only use your personal information to
              administer your account and to provide the products and services
              you requested from us. From time to time, we would like to contact
              you about our products and services, as well as other content that
              may be of interest to you. If you consent to us contacting you for
              this purpose, please tick below to say how you would like us to
              contact you:
            </p>

            <div className="mb-3 mb-md-2 text-center">
              <input
                type="checkbox"
                className="d-inline-block"
                name="agreement"
              />
              <span>
                I agree to receive other communications from Voltamond SA
              </span>
            </div>

            <p
              className="mb-3 mb-md-4 text-center"
              style={{ fontSize: "12px" }}
            >
              By clicking submit below, you consent to allow Voltamond SA to
              store and process the personal information submitted above to
              provide you the content requested.
            </p>

            <div className="text-center">
              <button type="submit" className="button d-inline-block">
                Submit
              </button>

              {showSuccess && (
                <div className="mt-4">
                  <h3 className="mb-1 color--green">
                    Thanks for getting in contact with us!
                  </h3>
                  <p className="paragraph--small">
                    We'll get back to you shortly.
                  </p>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
