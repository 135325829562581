import React from "react"

// Components
import VerticalTitle from "components/vertical-title/"

// Icons
import IconReddit from "assets/icons/icon-reddit.inline.svg"
import IconTelegram from "assets/icons/icon-telegram.inline.svg"
import IconTwitter from "assets/icons/icon-twitter.inline.svg"
import IconYouTube from "assets/icons/icon-youtube.inline.svg"
import IconLinkedIn from "assets/icons/icon-linkedin.inline.svg"
import IconFacebook from "assets/icons/icon-facebook.inline.svg"
import IconInstagram from "assets/icons/icon-instagram.inline.svg"
import IconTikTok from "assets/icons/icon-tiktok.inline.svg"
import IconDiscord from "assets/icons/icon-discord.inline.svg"
import IconVK from "assets/icons/icon-vk.inline.svg"
import IconMedium from "assets/icons/icon-medium.inline.svg"
import IconWhatsApp from "assets/icons/icon-whatsapp.inline.svg"

const Community = () => {
  const socialLinks = [
    {
      label: "Reddit",
      url: "https://www.reddit.com/user/ndbtechnology",
      icon: <IconReddit />,
    },
    {
      label: "Telegram",
      url: "https://t.me/ndbtechnology",
      icon: <IconTelegram />,
    },
    {
      label: "Twitter",
      url: "https://twitter.com/ndbtechnology",
      icon: <IconTwitter />,
    },
    {
      label: "Youtube",
      url: "https://www.youtube.com/c/NDBplus",
      icon: <IconYouTube />,
    },
    {
      label: "LinkedIn",
      url: "https://www.linkedin.com/company/ndbtechnology",
      icon: <IconLinkedIn />,
    },
    {
      label: "Facebook",
      url: "https://www.facebook.com/ndbtechnology",
      icon: <IconFacebook />,
    },
    {
      label: "Instagram",
      url: "https://www.instagram.com/ndbtechnology",
      icon: <IconInstagram />,
    },
    {
      label: "Tiktok",
      url: "https://www.tiktok.com/@ndbtechnology",
      icon: <IconTikTok />,
    },
    {
      label: "Discord",
      url: "https://discord.gg/w8DvXCNbyU",
      icon: <IconDiscord />,
    },
    {
      label: "VK",
      url: "https://vk.com/ndbtechnology",
      icon: <IconVK />,
    },
    {
      label: "Medium",
      url: "https://medium.com/@ndbtechnology",
      icon: <IconMedium />,
    },
    {
      label: "WhatsApp",
      url: "https://wa.me/0014152373257",
      icon: <IconWhatsApp />,
    },
  ]

  return (
    <section className="home__community section">
      <div className="container">
        <VerticalTitle title="Community" className="mb-4 mb-md-0" />

        <div className="content">
          <div>
            <h2 className="highlight mb-3">Find us</h2>
            <p>
              Our community is made up of developers, technologists, users, and
              enthusiasts from all over the globe.
            </p>
            <br />
            <p>
              No matter your background, you can be part of our community by
              participating in one of our online forums, following us on social
              media.
            </p>
          </div>

          <div className="social-links mt-3">
            <p className="mb-4">SEE UPCOMING EVENTS</p>
            <div className="row">
              {socialLinks.map(link => (
                <div className="col-4 col-sm-2 mb-4">
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                    title={link.label}
                  >
                    {link.icon}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Community
