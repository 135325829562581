import React from "react"

// Components
import { ButtonLink } from "components/button/"

// Icons
import IconHero from "assets/icons/home/icon-hero.inline.svg"
import IconTelegram from "assets/icons/icon-telegram.inline.svg"
import IconTwitter from "assets/icons/icon-twitter.inline.svg"
import IconYouTube from "assets/icons/icon-youtube.inline.svg"
import IconDiscord from "assets/icons/icon-discord.inline.svg"
import IconWhatsApp from "assets/icons/icon-whatsapp.inline.svg"
import IconFacebook from "assets/icons/icon-facebook.inline.svg"
import IconLinkedIn from "assets/icons/icon-linkedin.inline.svg"

const Hero = () => {
  // Social links
  const socialLinks = [
    {
      name: "Telegram",
      icon: <IconTelegram />,
      url: "https://t.me/ndbtechnology",
    },
    {
      name: "Twitter",
      icon: <IconTwitter />,
      url: "https://twitter.com/ndbtechnology",
    },
    {
      name: "YouTube",
      icon: <IconYouTube />,
      url: "https://www.youtube.com/c/NDBplus",
    },
    {
      name: "Discord",
      icon: <IconDiscord />,
      url: "https://discord.gg/ubB93xKe",
    },
    {
      name: "WhatsApp",
      icon: <IconWhatsApp />,
      url: "https://wa.me/0014152373257",
    },
    {
      name: "Facebook",
      icon: <IconFacebook />,
      url: "https://www.facebook.com/ndbtechnology",
    },
    {
      name: "LinkedIn",
      icon: <IconLinkedIn />,
      url: "https://www.linkedin.com/company/ndbtechnology",
    },
  ]

  return (
    <section className="home__hero section d-md-flex align-items-center justify-content-center">
      <div className="container">
        <IconHero className="icon" />
      </div>

      <div className="hero__cta">
        <ul className="hero__social-links d-flex d-md-none align-items-center justify-content-between mb-3">
          {socialLinks.map(link => (
            <li key={link.name}>
              <a
                href={link.url}
                className="svg-hover--stroke-green"
                target="_blank"
                rel="noopener noreferrer"
                title={link.name}
              >
                {link.icon}
              </a>
            </li>
          ))}
        </ul>
        <ButtonLink to="/" className="color--green">
          Earn Coins
        </ButtonLink>
      </div>
    </section>
  )
}

export default Hero
