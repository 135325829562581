import React from "react"

// Libraries
import PropTypes from "prop-types"
import { Link } from "gatsby"

export const ButtonLink = props => {
  const { children, className, to, state } = props

  return (
    <Link className={`button ${className}`} to={to} state={state}>
      {children}
    </Link>
  )
}

ButtonLink.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  to: PropTypes.string,
  state: PropTypes.objectOf(PropTypes.object),
}

ButtonLink.defaultProps = {
  children: null,
  className: null,
  to: null,
  state: null,
}

const Test = () => <p>Test</p>

export default Test
