import React from "react"

// Components
import VerticalTitle from "components/vertical-title/"
import { ButtonLink } from "components/button/"

// Assets
import logoNasdaq from "../../assets/images/home/partners/logo-nasdaq.png"
import logoNvidia from "../../assets/images/home/partners/logo-nvidia.png"
import logoClimatePledge from "../../assets/images/home/partners/logo-the-climate-pledge.png"
import logoAWS from "../../assets/images/home/partners/logo-aws.png"
import logoEpfl from "../../assets/images/home/partners/logo-epfl.png"
import logoBrookhaven from "../../assets/images/home/partners/logo-brookhaven.png"
import logoCern from "../../assets/images/home/partners/logo-cern.png"
import logoOrano from "../../assets/images/home/partners/logo-orano.png"
import logoLockheedMartin from "../../assets/images/home/partners/logo-lockheed-martin.png"
import logoParis from "../../assets/images/home/partners/logo-paris.png"

const Partners = () => {
  const partners = [
    logoNasdaq,
    logoNvidia,
    logoClimatePledge,
    logoAWS,
    logoEpfl,
    logoBrookhaven,
    logoCern,
    logoOrano,
    logoLockheedMartin,
    logoParis,
  ]

  return (
    <section className="home__partners section">
      <div className="container">
        <VerticalTitle
          title="Some of our partners"
          className="mb-4 mb-md-0"
          animated
        />

        <div className="partners">
          <div className="row">
            {partners.map(partner => (
              <div className="col-6 col-md-4 mb-3 mb-md-4" key={partner}>
                <div className="partner">
                  <img className="logo" src={partner} alt="" />
                </div>
              </div>
            ))}
          </div>

          <div className="partners__link text-center mt-4">
            <ButtonLink to="/">Become our partner</ButtonLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Partners
