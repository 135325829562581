import React from "react"

// Icons
// import IconTriangle from "assets/icons/icon-triangle.svg"
import IconAbout1 from "assets/icons/home/icon-about-1.inline.svg"
import IconAbout2 from "assets/icons/home/icon-about-2.inline.svg"
import IconAbout3 from "assets/icons/home/icon-about-3.inline.svg"
import { Link } from "gatsby"

const About = () => {
  const items = [
    {
      title: "Sustainable",
      description: (
        <p>
          NDB is a next-generation energy corporation that creates reliable{" "}
          <b>alternative sources</b> and solutions for a{" "}
          <b>sustainable clean future.</b>
        </p>
      ),
      icon: <IconAbout1 />,
      link: null,
    },
    {
      title: "Lifelong",
      description: (
        <>
          <p>
            NDB is a <b>lifelong self-charging green</b> battery that is made
            from isotopes or <b>recycled</b> nuclear waste.
            <br />
            <br />
            NDB does not run out of charge during the device's lifetime because
            it harnesses the power of energetic electron emission from
            radioisotopes.
          </p>
        </>
      ),
      icon: <IconAbout2 />,
      link: null,
    },
    {
      title: "Energy",
      description: (
        <>
          <p>
            We are committed to developing and manufacturing highly{" "}
            <b>innovative</b> clean energy solutions.
            <br />
            <br />
            Using <b>NDB App</b>, you can <b>trade</b>, <b>stake</b>, and{" "}
            <b>earn</b> tokens.
            <br />
            <br />
            You are, furthermore, accessing the <b>wallet</b>, <b>auctions</b>,
            and <b>rewards</b>.
            <br />
            <br />
            The app allows you to <b>purchase</b>, <b>monitor</b>, and{" "}
            <b>transfer</b> the ownership of NDB and our licensees' products.
          </p>
        </>
      ),
      icon: <IconAbout3 />,
      link: {
        label: "Go to Learn page to discover more",
        url: "/learn",
      },
    },
  ]

  return (
    <section className="home__about">
      <div className="container">
        <div className="about__title triangle text-center">
          <h1>About</h1>
        </div>

        <div className="about__items">
          {items.map(item => (
            <div className="item d-flex" key={item.title}>
              <div className="item__column item__icon">{item.icon}</div>

              <div className="item__column item__content">
                <div>
                  <h2 className="highlight mb-3">{item.title}</h2>
                  {item.description}

                  {item.link && (
                    <Link
                      to={item.link.url}
                      className="item__link d-inline-block mt-3 mt-md-0 color-hover--green font-weight--700"
                    >
                      {item.link.label}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default About
