import React from "react"

// Libraries
import { Link } from "gatsby"

// Icons
import IconFeatures1 from "assets/icons/home/icon-features-1.png"
import IconFeatures2 from "assets/icons/home/icon-features-2.png"

// Images
import image3 from "../../assets/images/home/features-image-3.png"
import image4 from "../../assets/images/home/features-image-4.png"

const Features = () => (
  <section className="home__features section">
    <div className="container h-100">
      <div className="feature h-100">
        <div className="row">
          <div className="col-12 col-md-5 mb-4 pt-md-5">
            <h1 className="title highlight mb-5">Empower your life</h1>

            <h2 className="subtitle">
              <i>Our blockchain ecosystem includes NDB and VOLT TOKENs</i>
            </h2>
          </div>

          <div className="col-12 col-sm-6 col-md-3 mb-4 pt-md-5">
            <p className="bordered">
              <span className="color--green">NDB token</span> will help you to
              contribute toward the development of energy solutions and
              technologies.
              <br />
              <br />
              <span className="color--green">VOLT TOKEN</span> converts into
              energy which gives you access to NDB and our partners' disruptive
              solutions.
            </p>
            <br />
            <br />
            <p className="bordered">
              Using <span className="color--green">NDB App</span>, you can
              trade, stack, and earn tokens. You are, furthermore, accessing the
              wallet, auctions, and rewards.
              <br />
              <br />
              The app allows you to purchase, monitor, and transfer the
              ownership of NDB and our licensees' products.
            </p>
          </div>

          <div className="col-12 col-sm-6 col-md-4 image-wrapper">
            <img src={IconFeatures1} alt="" />
          </div>
        </div>
      </div>

      <div className="feature h-100">
        <div className="row">
          <div className="col-12 col-md-5 mb-4 pt-md-5">
            <h1 className="title highlight mb-5">PRODUCTS AND SERVICES</h1>

            <h2 className="subtitle">
              We want to provide our customers early access to NDB's energy by
              trading their tokens at favorable rates against the energy
              produced by NDB.
            </h2>
          </div>

          <div className="col-12 col-sm-6 col-md-4 mb-4 pt-md-5">
            <p className="bordered">
              <span className="color--green">NDB Smart Energy</span>, composed
              of AI/ML-based energy subscriptions accessible via NDB apps, will
              promote energy saving and efficiency.
              <br />
              <br /> The subscription will optimize the energy consumption or
              the product's utilization period. It will also allow mining or
              selling energy back to the grid while the product is inactive.
              <br />
              <br />
              Furthermore, the carbon credits will be monitored and managed when
              it comes to partners and licensees.
            </p>
          </div>

          <div className="col-12 col-sm-6 col-md-3 image-wrapper">
            <img src={IconFeatures2} alt="" />
          </div>
        </div>
      </div>

      <div className="feature feature--tokens h-100">
        <div className="row justify-content-between">
          <div className="col-12 col-sm-6 col-md-5 mb-4 pt-md-5 image--tokens-wrapper">
            <h1 className="title highlight mb-5">pay with our tokens</h1>

            <h2 className="subtitle mb-5">
              VOLT TOKEN will be the payment method inside our ecosystem.
            </h2>

            <img className="image--tokens" src={image3} alt="" />
          </div>

          <div className="col-12 col-sm-4 col-md-3 mb-4 pt-md-5">
            <p className="bordered">
              With your NDB Wallet, you can pay your subscriptions and utility
              bills.
              <br />
              <br />
              Variety of subscriptions include energy and the services offered
              by Voltayou, Voltademy, Bloom, and the ones provided in the
              future.
            </p>
          </div>
        </div>
      </div>

      <div className="feature h-100">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6 mb-4 pt-md-5">
            <h1 className="title highlight mb-5">GET REWARD</h1>

            <img src={image4} className="feature-image-4" alt="" />
          </div>

          <div className="col-12 col-sm-6 col-md-4 mb-4 pt-md-5 d-md-flex flex-wrap">
            <p className="w-100 bordered">
              NDB allows you to earn VOLT TOKENs by simply plugging in your
              product powered by NDB to the grid when you are not using it.
              <br />
              <br />
              You will get as many VOLT TOKENs as the energy you supply to the
              grid.
              <br />
              <br />
              VOLT TOKENs can be issued through mining. Once a new block is
              generated, it will be granted to the miners as a reward.
              <br />
              <br />
              If the NDB powered product is inactive, it can mine and still
              benefit the customer.
            </p>

            <Link
              to="/technology"
              className="mt-5 d-inline-block align-self-end font-weight--700 color-hover--green"
            >
              GO TO TECHNOLOGY PAGE TO LEARN MORE
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
)
export default Features
