import React, { useState } from "react"

// Libraries
import PropTypes from "prop-types"

const Dropdown = ({ title, options, callbackFunction }) => {
  const [active, setActive] = useState(false)
  const [selectedOption, setSelectedOption] = useState("")

  const handleChange = option => {
    setSelectedOption(option)
    setActive(false)
    callbackFunction(option)
  }

  return (
    <div className={`dropdown ${active && "dropdown--active"}`}>
      <input
        list={title}
        onChange={e => handleChange(e.target.value)}
        placeholder={title}
      />
      <datalist id={title}>
        {options &&
          options.map(option => (
            <option
              key={option}
              aria-label={option}
              className={option === selectedOption && "active"}
              value={option}
            />
          ))}
      </datalist>
    </div>
  )
}

Dropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.instanceOf(PropTypes.array).isRequired,
  callbackFunction: PropTypes.func,
}

Dropdown.defaultProps = {
  title: null,
  callbackFunction: option => option,
}

export default Dropdown
